import React, { useState } from "react"
import { StoryTopic } from "../../types/storyTopic"
import {
  DatoCmsAction,
  DatoCmsActionProps,
  DatoCmsStoryTopicAction,
} from "../../components/DatoCmsAction"
import * as S from "./styled"
import { SectionTitle } from "../../components/Texts"
import { StoryThumbnail as IStoryThumbnail } from "../../types/story"
import { StoryThumbnail } from "../../components/StoryThumbnail"
import { SlideInTextAnimation } from "../../components/SlideInTextAnimation"
import { useImageSlideInSpring } from "../../hooks/useImageSlideinSpring"
import { animated } from "react-spring/web.cjs"

export const StoriesOverviewHeader: React.FC<{
  title: string
  categoriesTitle: string
  categories: StoryTopic[]
  action: DatoCmsActionProps
  stories: IStoryThumbnail[]
  currentTopicPath?: string | null
  withBottomMargin?: boolean
}> = ({
  title,
  categories,
  action,
  stories,
  categoriesTitle,
  currentTopicPath,
  withBottomMargin,
}) => {
  const [titleRested, setTileRested] = useState<boolean>(false)
  const { ref, spring } = useImageSlideInSpring({ pause: !titleRested })
  return (
    <S.Container withBottomMargin={withBottomMargin}>
      <S.TitleAndActionCell>
        <SlideInTextAnimation
          text={title}
          as={S.Title}
          onRest={() => setTileRested(true)}
        />
        <S.ActionWrapper ref={ref}>
          <animated.div style={spring}>
            <DatoCmsAction {...action} />
          </animated.div>
        </S.ActionWrapper>
      </S.TitleAndActionCell>

      <S.CategoriesCell>
        <SectionTitle>{categoriesTitle}</SectionTitle>
        <S.CategoriesContainer>
          {categories.map(s => (
            <DatoCmsStoryTopicAction
              gray={s.path !== currentTopicPath}
              clear={s.path === currentTopicPath}
              key={s.name}
              {...s}
            />
          ))}
        </S.CategoriesContainer>
      </S.CategoriesCell>
      {stories.map((story, i) => (
        <S.HeaderMessageCell key={story.id} index={i}>
          <StoryThumbnail animateInView {...story} />
        </S.HeaderMessageCell>
      ))}
    </S.Container>
  )
}
