import styled from "styled-components"
import { GridCell, GridLayout } from "../../components/GridLayout"
import device from "../../components/Theme/devices"

export const Container = styled(GridLayout).attrs({ greenish: true })<{
  withBottomMargin?: boolean
}>`
  h1 {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 39px;
    margin-top: 0;
    margin-bottom: 40px;
  }

  padding-top: 30px;
  padding-bottom: 32px;
  @media (${device.tablet}) {
    padding-top: 250px;
  }
  @media (${device.desktop}) {
    margin-bottom: ${props => (props.withBottomMargin ? 150 : 0)}px;
  }
`

export const TitleAndActionCell = styled(GridCell).attrs({
  row: "1 / 3",
  tabletRow: 1,
  column: "3 / 8",
  tabletCol: "2 / 8",
  leftMargin: "phone-only",
  rightMargin: "phone-only",
})``

export const Title = styled.h1`
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  white-space: pre-wrap;
`

export const ActionWrapper = styled.div`
  overflow: hidden;
`

export const CategoriesCell = styled(GridCell).attrs({
  column: "3 / 8",
  tabletCol: "2 / 8",
  tabletRow: 2,
  row: 3,
  leftMargin: "phone-only",
  rightMargin: "phone-only",
})`
  h2 {
    margin-top: 0;
    margin-bottom: 30px;
  }
`

export const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (${device.tablet}) {
    margin-left: -5px;
  }

  button {
    margin: 5px;
  }
  padding-bottom: 100px;
`

export const HeaderMessageCell = styled(GridCell).attrs(
  ({ index }: { index: number }) => {
    const baseRowTablet = index % 2 === 0 ? 3 : 4
    const rowTablet = baseRowTablet + 2 * Math.ceil((index + 1) / 2)

    // TODO generify
    let desktopRow
    switch (index) {
      case 0:
        desktopRow = " 2 / 4"
        break
      case 1:
        desktopRow = "4 / 6"
        break
      case 2:
        desktopRow = "5 / 7"
        break
      case 3:
        desktopRow = "7 / 9"
        break
      case 4:
        desktopRow = "8"
    }

    return {
      row: desktopRow,
      tabletRow: `${rowTablet} / ${rowTablet + 2}`,
      column: "",
      tabletCol: "",
      as: "article",
      leftMargin: "phone-only",
      rightMargin: "phone-only",
    }
  }
)<{ index: number }>`
  margin-bottom: 50px;
  @media (${device.tablet}) {
    :nth-of-type(2n - 1) {
      grid-column: 5 / 8;
    }

    :nth-of-type(2n) {
      grid-column: 2 / 5;
    }
  }

  @media (${device.desktop}) {
    margin-bottom: 70px;

    :nth-last-child(-n + 2) {
      margin-bottom: 0;
    }

    :last-child {
      transform: translateY(150px);
    }

    :nth-of-type(4n - 3) {
      grid-column: 9 / 13;
    }
    :nth-of-type(4n - 2) {
      grid-column: 4 / 8;
    }
    :nth-of-type(4n - 1) {
      grid-column: 9 / 13;
    }
    :nth-of-type(4n) {
      grid-column: 3 / 7;
    }
  }
`
