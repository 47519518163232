import React from "react"
import { graphql } from "gatsby"
// @ts-ignore
import { HelmetDatoCms } from "gatsby-source-datocms"
import { DatoCmsActionProps } from "../components/DatoCmsAction"
import { DatoCmsContentProps } from "../layout/DatoCmsContent/DatoCmsContent"
import { StoryTopic } from "../types/storyTopic"
import { StoryThumbnail } from "../types/story"
import { StoriesOverviewHeader } from "../layout/StoriesOverviewHeader"
import { Marquee } from "../components/Marquee"
import { Huge } from "../components/Texts"
import styled from "styled-components"
import device from "../components/Theme/devices"
import { StoryThumbnailSet } from "../layout/StoryThumbnailSet"
import { DatoCmsContent } from "../layout/DatoCmsContent"
import { Favicon } from "../components/Favicon"
import { useQueryParam, StringParam } from "use-query-params"

const Container = styled.main`
  > :nth-child(4) {
    padding-top: 60px;

    @media (${device.tablet}) {
      padding-top: 140px;
    }
  }
`

const MarqueeWrapper = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;

  @media (${device.tablet}) {
    padding-top: 100px;
    padding-bottom: 200px;
  }
`

const StoriesOverviewPage: React.FC<{ data: PageQueryType }> = ({
  data: { overviewData, categories, stories: _stories },
}) => {
  const [topic] = useQueryParam("topic", StringParam)
  const stories = _stories.edges.filter(
    s => !topic || s.node.categories.map(c => c.path).includes(topic)
  )
  const storiesFirstPart =
    stories.length > 4 && overviewData.marquee
      ? stories.slice(0, stories.length - 2)
      : stories
  const storiesLastPart =
    stories.length > 4 && overviewData.marquee
      ? stories.slice(stories.length - 2)
      : undefined
  return (
    <Container>
      <HelmetDatoCms seo={overviewData.seoMetaTags} />
      <Favicon />
      <StoriesOverviewHeader
        title={overviewData.title}
        categories={categories.edges.map(e => e.node)}
        action={overviewData.action}
        stories={storiesFirstPart.map(e => e.node)}
        categoriesTitle={overviewData.categoriesTitle}
        currentTopicPath={topic}
        withBottomMargin={!!overviewData.marquee}
      />
      {overviewData.marquee && (
        <Marquee
          textStyleComponent={Huge}
          text={overviewData.marquee}
          as={MarqueeWrapper}
        />
      )}
      {storiesLastPart && (
        <StoryThumbnailSet
          stories={storiesLastPart.map(e => e.node)}
          greenish
        />
      )}
      {overviewData.content.map(c => (
        //@ts-ignore
        <DatoCmsContent key={c.id} {...c} />
      ))}
    </Container>
  )
}

export default StoriesOverviewPage

type PageQueryType = {
  overviewData: {
    title: string
    categoriesTitle: string
    action: DatoCmsActionProps
    marquee: string
    content: DatoCmsContentProps[]
    seoMetaTags: any
  }

  categories: {
    edges: {
      node: StoryTopic
    }[]
  }

  stories: {
    edges: {
      node: StoryThumbnail
    }[]
  }
}

export const query = graphql`
  query storiesOverviewData {
    overviewData: datoCmsEntrepreneurialStoriesOverview {
      title
      categoriesTitle
      action {
        ...ActionData
      }
      marquee
      content {
        ... on DatoCmsDescriptionPlusMessaging {
          ...DescriptonAndMessagingData
        }
      }

      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    categories: allDatoCmsEntrepreneurialStoriesTopic {
      edges {
        node {
          ...storyTopic
        }
      }
    }
    stories: allDatoCmsEntrepreneurialStory(
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      edges {
        node {
          ...StoryThumbnail
        }
      }
    }
  }
`
